<template>
    <RadialProgress 
        :diameter="props.size"
        :completed-steps="currentCounter"
        :total-steps="props.maxCounter"
        :start-color="props.fillColor"
        :stop-color="props.fillColor"
        :inner-stroke-color="props.bgColor"
        :stroke-width="props.borderWidth"
        :inner-stroke-width="props.borderBgWidth"
        :animate-speed="props.animateSpeed"
        :is-clockwise="props.isClockwise"
    >
        {{ currentCounter }}{{ counterSuffix }}
    </RadialProgress>
  </template>
  
  <script setup>
    import { ref, onUnmounted, watch } from "vue";
    import RadialProgress from "vue3-radial-progress"

    const props = defineProps({
        size: {
            type: Number,
            default: 60
        },
        bgColor: {
            type: String,
            default: "#E2E6F9"
        },
        fillColor: {
            type: String,
            default: "#229F7D"
        },
        borderWidth: {
            type: Number,
            default: 4
        },
        borderBgWidth: {
            type: Number,
            default: 6
        },
        startFrom: {
            type: Number,
            default: 0
        },
        finishAt: {
            type: Number,
            default: 100
        },
        // counter: {
        //     type: Number,
        //     default: 0
        // },
        maxCounter: {
            type: Number,
            default: 100
        },
        counterSuffix: {
            type: String,
            default: "%"
        },
        animateSpeed: {
            type: Number,
            default: 20
        },
        isDirectCounting: {
            type: Boolean,
            default: true
        },
        isClockwise: {
            type: Boolean,
            default: true
        },
        pause: {
            type: Boolean,
            default: false
        }
    });

    const emit = defineEmits(['finished'])
    const currentCounter = ref(props.startFrom)

    const updateCounter = () => {
        if(props.pause) return

        if(
            (props.isDirectCounting && (currentCounter.value >= props.finishAt || currentCounter.value >= props.maxCounter))
            || (!props.isDirectCounting && (currentCounter.value <= props.finishAt || currentCounter.value <= 0))
        ){
            clearInterval(timer);
            emit('finished')
            return;
        }

        if(props.isDirectCounting)
            currentCounter.value++;
        else
            currentCounter.value--;
    }

    let timer = setInterval(updateCounter, props.animateSpeed)

    watch(
        () => props.finishAt,
        () => {
            clearInterval(timer);
            timer = setInterval(updateCounter, props.animateSpeed)
        }
    );

    onUnmounted(() => clearInterval(timer));    
  </script>