<template>
  <Sidebar />
  <section class="main-section">
    <div class="main-content">
      <PageHeader
        title="Welcome,"
        :subtitle="userName"
        titleReverseSize
      />

      <ThreeCards 
        :first-card-percent="state.sessionsComplete"
        first-card-title="Sessions Completed"
        :second-card-icon-src="secondCardIcon"
        :second-card-title="state.openPlans.length.toString()"
        second-card-sub-title="Open Plans"
        :third-card-icon-src="thirdCardIcon"
        :third-card-title="state.completedPlans.length.toString()"
        third-card-sub-title="Completed Plans"
      />

      <div v-if="noActivePlans" class="request-new_content">
        <div class="request-new_title">Request a new plan</div>
        <div class="request-new_text">You currently don't have an active plan, to request a new one, click the button below.</div>
        <GreenButton @click="$router.push('/explore-plans')">Request a new plan</GreenButton>
      </div>

      <NextSessionList
        :sessions="state.nextSessions"
        @view-session="nextSessionClick"
      />

      <PlanList
        title="Open Plans"
        :plans="state.openPlans"
        @plan-click="openPlanClick"
      />

      <PlanList
        title="Pending Plans"
        :plans="state.pendingPlans"
        @plan-click="openPlanClick"
      />

      <PlanList
        title="Failed Plans"
        :plans="state.failedPlans"
        @plan-click="openPlanClick"
      />

      <PlanList
        title="Stopped Plans"
        :plans="state.stoppedPlans"
        @plan-click="openPlanClick"
      />

      <PlanList
        title="Completed Plans"
        :plans="state.completedPlans"
        @plan-click="openPlanClick"
      />

      <ConversationList
        title="Messages"
        :conversations="state.conversations"
        @conversation-click="conversationClick"
      />

    </div>
  </section>
  
</template>

<script setup>
import { useAuthStore } from '../store/useAuthStore';
import { useStore } from '../store/useStore';
import Sidebar from './Sidebar.vue';
import PageHeader from './PageHeader.vue';
import PlanList from './PlanList.vue';
import ConversationList from './Conversation/ConversationList.vue'
import NextSessionList from './NextSessionList.vue';
import ThreeCards from './ThreeCards.vue';
import secondCardIcon from '../assets/img/icons/barbell.svg';
import thirdCardIcon from '../assets/img/icons/check.svg';
import { useDashboard } from '../hooks/useDashboard';
import { ref, reactive, computed, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { usePlanStore } from '../store/usePlanStore';
import GreenButton from './UI/GreenButton.vue';

const authStore = useAuthStore()
const store = useStore()
const router = useRouter()
const planStore = usePlanStore()
const dashboardHooks = useDashboard()

const noActivePlans = ref(false)

const userName = computed(() => authStore.user ? (authStore.user.name ? authStore.user.name : '') + ' ' + (authStore.user.last_name ? authStore.user.last_name : '') : '')

const state = reactive({
  sessionsComplete: 0,
  nextSessions: [],
  openPlans: [],
  pendingPlans: [],
  failedPlans: [],
  stoppedPlans: [],
  completedPlans: [],
  newMessagesCount: 0,
  conversations: []
})

function openPlanClick(plan) {
  router.push({
    name: 'plan',
    params: {
      id: plan.id
    }
  })
}

function nextSessionClick(session) {
  planStore.currentPlan.id = session.plan.id
  planStore.currentPlan.name = session.plan.name
  planStore.currentSession.number = session.number  

  router.push({
    name: 'session',
    params: {
      plan_id: session.plan.id,
      week: session.week,
      week_day: session.week_day
    }
  })
}

function conversationClick(conversation) {
  router.push({
    name: 'planMessages',
    params: {
      plan_id: conversation.plan_id
    }
  })
}

async function loadDashboardData(){
  await dashboardHooks.fetching()

  state.sessionsComplete = dashboardHooks.sessionsComplete
  state.nextSessions = dashboardHooks.nextSessions
  state.openPlans = dashboardHooks.openPlans
  state.pendingPlans = dashboardHooks.pendingPlans
  state.failedPlans = dashboardHooks.failedPlans
  state.stoppedPlans = dashboardHooks.stoppedPlans
  state.completedPlans = dashboardHooks.completedPlans
  state.newMessagesCount = dashboardHooks.newMessagesCount
  state.conversations = dashboardHooks.conversations
}

onMounted(() => {
  loadDashboardData()
})

watch(
  [
    () => state.openPlans.value,
    () => state.pendingPlans.value,
    () => state.failedPlans.value,
    () => state.stoppedPlans.value,
  ],
  ([newOpenPlans, newPendingPlans, newFailedPlans, newStoppedPlans]) => {
    noActivePlans.value = newOpenPlans.length>0 || newPendingPlans.length>0 || newFailedPlans.length>0 || newStoppedPlans.length>0 ? false : true
  }
)

watch(
  () => state.newMessagesCount.value,
  (num) => {
    store.newMessagesCount = num
  }
)

</script>

<style scoped>
.request-new_content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  width: 100%;
}

.request-new_title {
  padding: 0px;
  gap: 16px;
  font-weight: 600;
  font-size: 1.25rem;
  color: #28224B;
}

.request-new_text {
  padding: 0px;
  gap: 16px;
  color: #7D7E8A;
}
</style>
